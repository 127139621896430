<template>
	<van-popup v-model:show="visible" position="center" :style="{ width: '90%'}" @close="close">
		<div class="poster_container" id="poster_container" v-if="!posterUrl">
			<div class="hedaer">
				<img :src="detail.picUrl+'?'+new Date().getTime()" crossOrigin="anonymous"  class="pic" alt="">
				<div class="name">
					<img src="../assets/logo.png" class="logo" alt="">
					<div class="title">{{detail.name}}</div>
				</div>
			</div>
			<div class="content">{{detail.brief}}</div>
		</div>
		<div class="poster_img" v-else>
			<van-icon name="close" size="20" @click="close"/>
			<img :src="posterUrl" class="posterUrl" alt="">
			<div class="footer">
				<button class="button">长按图片保存</button>
			</div>
		</div>
	</van-popup>
</template>

<script>
	import html2canvas from "html2canvas";
	import {
		reactive,
		toRefs,
		watch,
		nextTick,
		defineExpose
	} from 'vue'
	import {
		Toast
	} from 'vant'
	export default {
		props: {
			info: {
				type: Object,
				default: () => {}
			}
		},
		setup(props, {emit}) {
			const state = reactive({
				posterUrl: '',
				visible: false,
				detail: {}
			})
			watch(() => props.info, newValue => {
				if (Object.keys(newValue).length == 0) return;
				Toast.loading({
					message: '海报生成中，请稍等...',
					forbidClick: true
				});
				state.detail = newValue;
				state.visible = true;
				nextTick(() => {
					toSaveImage();
				});
			}, {
				deep: true
			})
			const close = () => {
				state.visible = false;
				state.posterUrl = '';
				state.detail = {};
				emit('poster-change', {
					empty: true
				})
			}
			
			const toSaveImage = async () => {
				var imageWrapper = document.getElementById("poster_container");
				let canvas = await html2canvas(imageWrapper, {
					height: imageWrapper.offsetHeight,
					width: imageWrapper.offsetWidth,
					useCORS: true,
					scrollX: 0,
					scrollY: 0,
					dpi: window.devicePixelRatio * 2,
				});
				state.posterUrl = canvas.toDataURL();
				Toast.clear()
			}
			return {
				...toRefs(state),
				toSaveImage,
				close,
			}
		}
	}
</script>

<style scoped lang="less">
	
	.poster_container {
		width: 100%;
		background-color: #fff;

		.hedaer {
			width: 100%;
			height: 55%;
			background-color: #E0CEB6;
			box-sizing: border-box;
			padding: 20px 4px 0 4px;
			position: relative;
			
			.pic {
				width: 100%;
				height: 280px;
				border-radius: 8px;
			}

			.name {
				display: flex;
				align-items: center;

				.logo {
					width: 82px;
					height: 100px;
				}

				.title {
					text-align: center;
					width: calc(100% - 80px);
					font-size: 20px;
					font-weight: bold;
					margin-left: 10px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}

		.content {
			width: 100%;
			box-sizing: border-box;
			padding: 10px 16px 16px 16px;
			line-height: 32px;
			background-color: #fff;
			text-align: center;
			font-size: 16px;
		}

	}

	.footer {
		width: 100%;
		height: 50px;
		text-align: center;
		// margin-top: 50px;
		.button {
			width: 92%;
			height: 40px;
			line-height: 40px;
			background: linear-gradient(180deg, #e9192e, #fc5d3e);
			border-radius: 20px;
			color: #ffffff;
			font-size: 16px;
			border: none;
		}
	}
	.poster_img{
		position: relative;
		.van-icon-close{
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}

	.posterUrl {
		width: 100%;
	}
</style>
